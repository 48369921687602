<template>
  <div v-loading="isLoading" class="disk-quota">
    <div class="view-content">
      <div class="left">
        <div class="item">
          <div class="box">
            <div>
              <img src="@/assets/icon/disk.png" alt="">
            </div>
            <div>
              <div class="box-name">磁盘总容量(GB)</div>
              <div class="unit"><span class="number">{{ spaceCount }}</span></div>
            </div>
          </div>
          <div class="box">
            <div>
              <img src="@/assets/icon/validity.png" alt="">
            </div>
            <div>
              <div class="box-name">有效期</div>
              <div class="unit"><span class="number">{{ validity }}</span></div>
            </div>
          </div>
        </div>
        <div class="buy-btn">
          <el-button round @click="buyClick">购买</el-button>
        </div>
      </div>
      <div class="right">
        <div id="quotaChart" class="pie-echart" style="width:100%; height:160px" />
      </div>
    </div>
    <div v-if="isBuy" class="buy-box">
      <div class="title">容量</div>
      <div class="capacity">
        <div v-for="(item,i) in capacityList" :key="i" class="item" :class="activeCapacity === i ? 'select' : ''" @click="capacityChange(item,i)">
          <div class="capacity-text">{{ item.number }}{{ item.unit }}</div>
          <div v-show="activeCapacity === i" class="select-icon">
            <img src="@/assets/icon/select-icon.png" alt="">
          </div>
          <div v-show="activeCapacity === i" class="select-icons">
            <img src="@/assets/icon/select-icons.png" alt="">
          </div>
        </div>
      </div>
      <div v-if="isFirst" class="title">时间</div>
      <div v-if="isFirst" class="date">
        <div v-for="(item,i) in dateList" :key="i" class="item" :class="activeDate === i ? 'select' : ''" @click="dateChange(item,i)">
          <div class="date-text">{{ item.date }}</div>
          <div v-show="activeDate === i" class="select-icon">
            <img src="@/assets/icon/select-icon.png" alt="">
          </div>
          <div v-show="activeDate === i" class="select-icons">
            <img src="@/assets/icon/select-icons.png" alt="">
          </div>
        </div>
      </div>
      <div class="btn-box">
        <el-button :loading="buyLoading" :disabled="btnDisabled" type="primary" round @click="confirmBuy">
          <span v-if="isShowPrice">确认支付 {{ totalPrice }}元</span>
          <span v-else>确认购买</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuota, setQuota, getQuotaDeatilById, getQuotaStates, getQuotaPrice } from '@/api/task'
import { markRaw } from 'vue'
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      buyLoading: false,
      isFirst: true, // 是否是第一次购买
      totalPrice: null,
      isShowPrice: false,
      quotaPrice: null,
      quotaId: null,
      quota: null,
      months: null,
      btnDisabled: false,
      // 容量
      activeCapacity: -1,
      capacityList: [{
        id: 1,
        number: 500,
        unit: 'GB'
      }, {
        id: 2,
        number: 1,
        unit: 'TB'
      }, {
        id: 3,
        number: 2,
        unit: 'TB'
      }, {
        id: 4,
        number: 5,
        unit: 'TB'
      }, {
        id: 5,
        number: 10,
        unit: 'TB'
      }],
      // 日期
      activeDate: -1,
      dateList: [{
        id: 0,
        date: '1个月'
      }, {
        id: 1,
        date: '3个月'
      }, {
        id: 2,
        date: '6个月'
      }, {
        id: 3,
        date: '1年'
      }, {
        id: 4,
        date: '2年'
      }, {
        id: 5,
        date: '3年'
      }],
      isBuy: false,
      validity: '',
      myChart: null,
      spaceCount: 0,
      spaceData: [],
      spaceObj: {
        'usage': 220,
        'limit': 209715200
      },
      isLoading: false
    }
  },
  mounted() {
    this.getQuotaData()
    this.getQuotaDeatil()
    const elementResizeDetectorMaker = require('element-resize-detector')// 导入
    // 创建实例
    const erd = elementResizeDetectorMaker()
    // 创建实例带参
    const erdUltraFast = elementResizeDetectorMaker({
      strategy: 'scroll', // <- For ultra performance.
      callOnAdd: true,
      debug: true
    })
    const echarts = require('echarts')
    const chartSpacePies = echarts.init(document.getElementById('quotaChart'))
    // 监听id为test的元素 大小变化
    const i = document.getElementsByClassName('disk-quota').length - 1
    erd.listenTo(document.getElementsByClassName('disk-quota')[i], function(element) {
      try {
        setTimeout(() => {
          chartSpacePies.resize()
        }, 100)
      } catch (error) {
        console.log(error)
      }
    })
  },
  methods: {
    // 获取磁盘配额容量详情
    getQuotaDeatil() {
      const data = {
        quotaIdList: '1000591134343135,1000591134343136,1000591134343137'
      }
      getQuotaDeatilById(data).then(res => {
        this.capacityList = res.data
        this.capacityList.forEach((e) => {
          if (e.quota >= 1024) {
            e.number = e.quota / 1024
            e.unit = 'TB'
          } else {
            e.number = e.quota
            e.unit = 'GB'
          }
        })
      })
    },
    //  确认购买
    confirmBuy() {
      this.btnDisabled = true
      const data = {
        months: this.months,
        quotaID: this.quotaId
      }
      if (this.isFirst) {
        if (this.months !== null && this.quotaId !== null) {
          this.setQuota(data)
        } else {
          ElMessage.warning('请您选择容量和时间')
        }
      } else {
        this.setQuota(data)
      }
    },
    setQuota(data) {
      setQuota(data).then(res => {
        if (res.meta.status === 200) {
          // ElMessage.success('购买成功')
          this.getQuotaStates(res.data.eventLogId)
        } else {
          ElMessage.error(res.meta.msg)
        }
      })
    },
    // 磁盘配额回调
    getQuotaStates(id) {
      const data = {
        eventID: id
      }
      getQuotaStates(data).then(res => {
        if (res.meta.status === 200) {
          if (res.data.code === 200) {
            this.getQuotaData()
            ElMessage.success('购买成功')
          } else if (res.data.code === 30040) {
            ElMessage.error('扩容失败，您购买的规格小于您现有的规格！')
          } else if (res.data.code === 30041) {
            ElMessage.error('扩容失败，您的账户余额不足！')
          } else {
            ElMessage.error('扩容失败！')
          }
          this.btnDisabled = false
        } else {
          ElMessage.error(res.meta.msg)
          this.btnDisabled = false
        }
      }).catch(() => {
        this.btnDisabled = false
      })
    },
    // 时间选择改变
    dateChange(item, i) {
      this.activeDate = i
      if (item.id === 0) {
        this.months = 1
      } else if (item.id === 1) {
        this.months = 3
      } else if (item.id === 2) {
        this.months = 6
      } else if (item.id === 3) {
        this.months = 12
      } else if (item.id === 4) {
        this.months = 24
      } else if (item.id === 5) {
        this.months = 36
      }
      if (this.months !== null && this.quotaId !== null) {
        this.buyLoading = true
        this.getQuotaPrice(this.months, this.quotaId)
        // this.totalPrice = this.quotaPrice * this.months
        // this.isShowPrice = true
      }
    },
    // 获取磁盘配额价格
    getQuotaPrice(months, id) {
      if (this.spaceCount < this.quota) {
        const data = {
          quotaID: id,
          months: months
        }
        getQuotaPrice(data).then(res => {
          if (res.meta.status === 200) {
            this.totalPrice = (parseFloat(res.data.realMoney).toFixed(0)) / 1000
            this.isShowPrice = true
            this.buyLoading = false
          } else {
            this.buyLoading = false
          }
        }).catch(() => {
          this.buyLoading = false
        })
      } else {
        this.isShowPrice = false
        this.buyLoading = false
      }
    },
    // 容量选择改变
    capacityChange(item, i) {
      this.quota = item.quota
      this.quotaId = item.quotaId
      this.quotaPrice = item.price / 1000
      this.activeCapacity = i
      if (this.isFirst) {
        if (this.months !== null && this.quotaId !== null) {
          this.buyLoading = true
          // this.totalPrice = this.quotaPrice * this.months
          // this.isShowPrice = true
          this.getQuotaPrice(this.months, this.quotaId)
        }
      } else {
        this.buyLoading = true
        this.getQuotaPrice(this.months, this.quotaId)
      }
    },
    // 点击购买按钮
    buyClick() {
      this.isBuy = true
    },
    // 获取磁盘配额
    getQuotaData() {
      this.isLoading = true
      var unUsage = 0
      getQuota().then(res => {
        if (res.meta.status === 200) {
          if (res.data.endTime === null) {
            this.validity = '永久有效'
            this.isFirst = true
          } else {
            this.validity = res.data.endTime
            this.isFirst = false
          }
          this.spaceObj = res.data
          if (this.spaceObj.limit === 0) {
            unUsage = this.spaceObj.usage
            this.spaceCount = '无限制'
            this.spaceData.push({
              name: '已使用',
              value: parseFloat((this.spaceObj.usage / 1048576).toFixed(1))
            }, {
              name: '未使用',
              value: parseFloat((unUsage).toFixed(1))
            })
            this.$nextTick(() => {
              this.initSpaceEcharts()
              this.isLoading = false
            })
          } else {
            unUsage = this.spaceObj.limit - this.spaceObj.usage
            this.spaceCount = (this.spaceObj.limit / 1048576).toFixed(0)
            this.spaceData.push({
              name: '已使用',
              value: parseFloat((this.spaceObj.usage / 1048576).toFixed(1))
            }, {
              name: '未使用',
              value: parseFloat((unUsage / 1048576).toFixed(1))
            })
            console.log(this.spaceData)
            this.$nextTick(() => {
              console.log(this.spaceData)
              this.initSpaceEcharts()
              this.isLoading = false
            })
          }
        }
      }).catch(() => {
        this.$nextTick(() => {
          this.initSpaceEcharts()
          this.isLoading = false
        })
      })
    },
    // 用户空间 饼图
    initSpaceEcharts() {
      const echarts = require('echarts')
      this.myChart = markRaw(echarts.init(document.getElementById('quotaChart')))
      const option = {
        color: ['#FD8E3F', '#249EFF'],
        title: {
          text: this.spaceCount,
          left: 'center',
          top: 'center',
          textStyle: {
            color: '#313a46',
            fontSize: 18
          },
          subtext: '全部(GB)',
          subtextStyle: {
            color: '#939ea9',
            fontSize: 12,
            align: 'right'
          },
          triggerEvent: true
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}:{c}GB({d}%)'
        },
        legend: {
          orient: '', // 删除此项图例是水平展示，添加此项图例垂直展示
          x: 'right', // x : 左（left）、右（right）、居中（center）
          y: 'center', // y : 上（top）、下（bottom）、居中（center）
          itemHeight: 8,
          padding: [0, 0, 0, 0], // padding:[0,30,0,0] [（上），（右）、（下）、（左）]
          icon: 'circle',
          textStyle: {
            // 图例文字的样式
            color: '#4E5969',
            fontSize: 12
          },
          data: ['未使用', '已使用']
        },
        series: [
          {
            name: '磁盘配额',
            type: 'pie',
            radius: ['69%', '93%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              normal: {
                show: false,
                position: 'outside', // 另有参数inside，可以让数据在圆环上
                formatter: '{d}%', // 模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
                textStyle: {
                  // 牵引线上的文字的样式
                  align: 'right',
                  fontSize: 12,
                  color: '#333'
                }
              }
            },
            labelLine: {
              show: false // 是否显示延展线
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            data: this.spaceData
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.disk-quota{
  padding: 29px 20px 69px 20px;
  height: 100%;
  overflow: auto;
  .buy-box{
    min-width: 850px;
    padding: 37px 20px;
    .btn-box{
      width: 100%;
      text-align: center;
      padding-top: 31px;
      .el-button.is-round {
        padding: 16px 55px;
        border-radius: 30px;
        font-size: 20px;
      }
    }
    .title{
      font-size: 16px;
      font-weight: bold;
      color: #595F67;
      line-height: 24px;
      padding-bottom: 8px;
    }
    .capacity,
    .date{
      display: flex;
      flex-wrap: wrap;
    }
    .item {
      margin-bottom: 25px;
      text-align: center;
      padding-top: 16px;
      border: 1px #e7e7e7 solid;
      width: 128px;
      height: 56px;
      border-radius: 4px;
      margin-right: 16px;
      position: relative;
    }
    .capacity-text,
    .date-text{
      font-weight: 500;
      color: #000000;
      font-size: 16px;
    }
    .select {
      position: relative;
      color: #437AEC;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #437AEC;
      font-size: 0;
    }
    .select-icon{
      position: absolute;
      right: 0;
      bottom: 0;
      display: table-cell;
      img{
        display: block;
        width: 34px;
        height: 36px;
      }
    }
    .select-icons{
      position: absolute;
      right: 2px;
      bottom: 2px;
      display: table-cell;
      img{
        display: block;
        width: 16px;
        height: 16px;
      }
    }
  }
      .view-content{
        display: flex;
        justify-content: space-around;
        align-items: center;
        min-width: 850px;
        .buy-btn{
          margin-left: 20px;
          .el-button{
            font-size: 16px;
            color: #437AEC;
            border-color: #437AEC;
          }
          .el-button.is-round{
            margin-top: 6px;
            border-radius: 26px;
          }
        }
        .item{
          display: flex;
          width: 100%;
          height: 120px;
          .box{
            display: flex;
            align-items: center;
            padding: 0 20px;
            // min-width: 160px;
            width: 50%;
            min-width: 208px;
            box-sizing: border-box;
            img{
              margin-right: 12px;
            }
            .box-name{
              padding-bottom: 2px;
              line-height: 22px;
              color: #303133;
              font-size: 14px;
            }
            .unit{
              color: #4E5969;
              font-size: 12px;
            }
            .number{
              font-size: 14px;
              line-height: 22px;
              color: #606266;
            }
          }
        }
        .left{
          width: 50%;
          // border-left: 1px solid #f2f3f5;
          margin-right: 3%;
        }
        .right{
          width: 50%;
          border-left: 1px solid #f2f3f5;
          .pie-echart {
            height: 160px;
            width: 100%;
          }
        }
      }
}
</style>
